import KpiHeader from '@/components/widgets/KpiHeader.vue';
import { kpiStore } from '@/state/kpiStore';
import { defineComponent } from 'vue';
import KpiChartWidget from './KpiChartWidget.vue';
import KpiTrendWidget from './KpiTrendWidget.vue';
export default defineComponent({
  components: {
    KpiHeader: KpiHeader,
    KpiChartWidget: KpiChartWidget,
    KpiTrendWidget: KpiTrendWidget
  },
  directives: {},
  props: {
    widgetSettings: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    kpiKey: function kpiKey() {
      return this.widgetSettings.kpiKey;
    },
    kpiDefinition: function kpiDefinition() {
      if (!this.kpiKey) {
        return undefined;
      }

      return kpiStore.getters.getKpiDefinition(this.kpiKey);
    },
    supportsHistory: function supportsHistory() {
      var _a;

      return (_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.supportsHistory;
    },
    useTrendWidget: function useTrendWidget() {
      return !this.supportsHistory;
    },
    useChartWidget: function useChartWidget() {
      return this.supportsHistory;
    }
  },
  watch: {},
  mounted: function mounted() {},
  methods: {}
});