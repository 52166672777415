import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { openLinkInUi as _openLinkInUi } from '@/uiCommunication';
import { defineComponent } from 'vue';
import { dashboardsStore, getWidgetOverrideDaterange } from '@/state/dashboardsStore';
import { teamsStore } from '@/state/teamsStore';
import { kpiStore } from '@/state/kpiStore';
export default defineComponent({
  props: {
    widgetSettings: {
      type: Object,
      "default": null
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    editMode: function editMode() {
      return dashboardsStore.getters.editMode;
    },
    kpiDefinition: function kpiDefinition() {
      var _a;

      var kpiKey = (_a = this.widgetSettings) === null || _a === void 0 ? void 0 : _a.kpiKey;

      if (kpiKey) {
        return kpiStore.getters.getKpiDefinition(kpiKey);
      }

      return undefined;
    },
    viewLink: function viewLink() {
      var _a, _b;

      var globalContext = dashboardsStore.getters.kpiGlobalContext; // override local context

      return (_b = (_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.getViewLinkToDetails) === null || _b === void 0 ? void 0 : _b.call(_a, globalContext);
    },
    behandlerFilter: function behandlerFilter() {
      var _this = this;

      var _a, _b;

      if (((_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.supportsBehandlerFilter) && this.widgetSettings.overrideTeam) {
        return (_b = teamsStore.getters.teams.find(function (t) {
          return t.id === _this.widgetSettings.overrideTeamId;
        })) === null || _b === void 0 ? void 0 : _b.name;
      }

      return undefined;
    },
    overrideDaterangeLabel: function overrideDaterangeLabel() {
      var dr = getWidgetOverrideDaterange(this.widgetSettings);
      return dr === null || dr === void 0 ? void 0 : dr.label;
    }
  },
  methods: {
    openLinkInUi: function openLinkInUi(link) {
      _openLinkInUi(link);
    }
  }
});