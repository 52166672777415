import _toConsumableArray from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.concat.js";
import { kpiStore } from '@/state/kpiStore';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    value: {
      type: String,
      "default": ''
    }
  },
  data: function data() {
    return {
      showAll: false,
      activeColumns: [],
      selectedKpi: null
    };
  },
  computed: {
    kpiDefinitionTree: function kpiDefinitionTree() {
      return kpiStore.getters.kpiDefinitionTree;
    },
    kpiDefinitions: function kpiDefinitions() {
      return kpiStore.getters.kpiDefinitions;
    },
    kpiDefinitionsAuthFiltered: function kpiDefinitionsAuthFiltered() {
      return kpiStore.getters.kpiDefinitionsAuthFiltered;
    },
    currentKpiPath: function currentKpiPath() {
      return this.findPathToKpiTreeItem(this.value);
    },
    currentKpiPathString: function currentKpiPathString() {
      return this.currentKpiPath.slice(0, -1).map(function (e) {
        return e.name;
      }).join(' / ');
    },
    kpiDefinitionsSiblings: function kpiDefinitionsSiblings() {
      var _a;

      var path = this.currentKpiPath;

      if (path.length < 2) {
        return [];
      }

      return ((_a = path[path.length - 2].childs) !== null && _a !== void 0 ? _a : []).map(function (e) {
        return e.kpiDef;
      }).filter(function (d) {
        return !!d;
      });
    }
  },
  mounted: function mounted() {
    this.activeColumns = [this.kpiDefinitionTree];
  },
  methods: {
    confirmSelection: function confirmSelection() {
      if (!this.selectedKpi) {
        return;
      }

      this.$emit('input', this.selectedKpi.kpiKey);
      this.showAll = false;
    },
    setActiveKpiKey: function setActiveKpiKey(kpiKey) {
      this.$emit('input', kpiKey);
    },
    showAllKpis: function showAllKpis() {
      this.showAll = true;
      this.setSearchActiveKpiKey(this.value);
    },
    setSearchActiveKpiKey: function setSearchActiveKpiKey(kpiKey) {
      var _a;

      this.selectedKpi = (_a = this.kpiDefinitions.find(function (kpi) {
        return kpi.kpiKey === kpiKey;
      })) !== null && _a !== void 0 ? _a : null;

      if (this.selectedKpi) {
        // set the active columns to the path
        var path = this.findPathToKpiTreeItem(kpiKey);
        this.activeColumns = [this.kpiDefinitionTree].concat(_toConsumableArray(path.map(function (item) {
          var _a;

          return (_a = item.childs) !== null && _a !== void 0 ? _a : [];
        })));
        this.scrollToRight();
      }
    },
    findPathToKpiTreeItem: function findPathToKpiTreeItem(kpiKey) {
      return kpiStore.getters.findPathToKpiTreeItem(kpiKey);
    },
    openItem: function openItem(item, depth) {
      console.log('openItem', item); // clear activeColumns after the clicked depth

      this.activeColumns = this.activeColumns.slice(0, depth + 1);

      if (item.kpiDef) {
        this.selectedKpi = item.kpiDef;
      }

      if (item.childs && item.childs.length > 0) {
        this.activeColumns.push(item.childs);
        this.scrollToRight();
      }
    },
    scrollToRight: function scrollToRight() {
      var _this = this;

      // scroll list to the right
      this.$nextTick(function () {
        var list = _this.$refs.millerList;
        list.scrollTo({
          left: list.scrollWidth,
          behavior: 'smooth'
        });
      });
    }
  }
});