import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import { roseDayjs, RoseDayJs } from '../../../base';
import { IDashboard, IDashboardSettings, IKpiContext, ITeam, IWidgetSettings } from '../../../types';
import { viewStateStore } from '@/state/viewStateStore';
import { formatApiErrorForUser, isDev, sendCommandToUi } from '@rose/common-ui';
import { metricsApi } from '@/services/data';
import { notifyError, notifySuccess } from '@/services/notify.service';
import { cloneDeep } from 'lodash';

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    // widget context
    daterange: { from: roseDayjs().subtract(7 - 1, 'days'), to: roseDayjs() },
    teamSelected: null as null | ITeam,

    // other
    editMode: false,
    uneditedDashboardSettings: null as IDashboardSettings | null,
    dashboardSettings: null as IDashboardSettings | null,
  }),
  getters: {
    teamSelected(state) {
      return state.teamSelected;
    },
    daterange(state) {
      return state.daterange;
    },
    teamIds(state) {
      return state.teamSelected ? [state.teamSelected.id] : null;
    },
    widgetGlobalContext(state) {
      return {
        daterange: state.daterange,
        teamIds: state.teamSelected ? [state.teamSelected] : [],
      };
    },
    kpiGlobalContext(state): IKpiContext {
      return {
        from: state.daterange.from.format('YYYY-MM-DD'),
        to: state.daterange.to.format('YYYY-MM-DD'),
        teamIds: state.teamSelected ? [state.teamSelected.id] : null,
      };
    },
    kpiGlobalContextForConfig(state) {
      return (widgetSettings: IWidgetSettings) => ({
        from: state.daterange.from.format('YYYY-MM-DD'),
        to: state.daterange.to.format('YYYY-MM-DD'),
        teamIds: widgetSettings.overrideTeam
          ? [widgetSettings.overrideTeamId!]
          : state.teamSelected
          ? [state.teamSelected.id]
          : null,
      });
    },
    editMode(state) {
      return state.editMode;
    },
  },
  mutations: {
    setDaterange(state, daterange: { from: RoseDayJs; to: RoseDayJs }) {
      state.daterange = daterange;
    },
    setTeamSelected(state, team) {
      state.teamSelected = team;
    },
    setEditMode(state, editMode: boolean) {
      state.editMode = editMode;
    },
    setDashboardSettings(state, dashboardSettings: IDashboardSettings) {
      state.dashboardSettings = dashboardSettings;
    },
    setUneditedDashboardSettings(state, dashboardSettings: IDashboardSettings) {
      state.uneditedDashboardSettings = dashboardSettings;
    },
    setDashboards(state, dashboards: IDashboard[]) {
      if (state.dashboardSettings) {
        state.dashboardSettings.dashboards = dashboards;
      }
    },
  },
  actions: {
    async updateViewState(context) {
      const { state } = rootActionContext(context);
      await viewStateStore.dispatch.addToViewState({
        von: state.daterange.from.format('YYYY-MM-DD'),
        bis: state.daterange.to.format('YYYY-MM-DD'),
        team: state.teamSelected?.id,
      });
    },

    setDaterange(context, data: { from: RoseDayJs; to: RoseDayJs }) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setDaterange(data);
    },
    setTeamSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setTeamSelected(team);
    },

    startEditMode(context) {
      const { commit } = rootActionContext(context);

      sendCommandToUi({ command: 'setCanNavigate', canNavigate: false });

      commit.setUneditedDashboardSettings(cloneDeep(context.state.dashboardSettings));
      commit.setEditMode(true);
    },

    async endEditMode(context, { saveChanges }: { saveChanges: boolean }) {
      const { commit, dispatch } = rootActionContext(context);

      sendCommandToUi({ command: 'setCanNavigate', canNavigate: true });

      console.log('endEditMode save: ', saveChanges);
      commit.setEditMode(false);

      if (saveChanges) {
        await dispatch.saveDashboardSettings();
      } else {
        commit.setDashboardSettings(context.state.uneditedDashboardSettings!);
      }
    },

    async saveDashboardSettings(context) {
      const { state, commit, dispatch } = rootActionContext(context);

      if (!state.dashboardSettings) {
        return;
      }

      try {
        await metricsApi.dashboards.saveDashboardSettings({
          dashboardSettings: state.dashboardSettings,
        });
        notifySuccess('gespeichert');
      } catch (e) {
        notifyError('Fehler beim speichern: ' + formatApiErrorForUser(e));
      }
    },
  },
});

export const dashboardsStore = store;

export const widgetOverrideDateranges = [
  { label: 'Heute', key: 'today', daterange: { from: roseDayjs(), to: roseDayjs() } },
  {
    label: 'Gestern',
    key: 'yesterday',
    daterange: { from: roseDayjs().subtract(1, 'day'), to: roseDayjs().subtract(1, 'day') },
  },

  // woche
  { label: '1 Woche', key: 'lastWeek', daterange: { from: roseDayjs().subtract(1, 'week'), to: roseDayjs() } },
  { label: 'Diese Woche', key: 'thisWeek', daterange: { from: roseDayjs().startOf('week'), to: roseDayjs() } },
  {
    label: 'Vorherige Woche',
    key: 'previousWeek',
    daterange: { from: roseDayjs().subtract(1, 'week').startOf('week'), to: roseDayjs().startOf('week') },
  },

  // monat
  { label: '1 Monat', key: 'lastMonth', daterange: { from: roseDayjs().subtract(1, 'month'), to: roseDayjs() } },
  { label: 'Dieser Monat', key: 'thisMonth', daterange: { from: roseDayjs().startOf('month'), to: roseDayjs() } },
  {
    label: 'Vorheriger Monat',
    key: 'previousMonth',
    daterange: { from: roseDayjs().subtract(1, 'month').startOf('month'), to: roseDayjs().startOf('month') },
  },

  // quartal
  { label: 'Dieses Quartal', key: 'thisQuarter', daterange: { from: roseDayjs().startOf('quarter'), to: roseDayjs() } },
  {
    label: 'Vorheriges Quartal',
    key: 'previousQuarter',
    daterange: { from: roseDayjs().subtract(1, 'quarter').startOf('quarter'), to: roseDayjs().startOf('quarter') },
  },

  // jahr
  { label: 'Dieses Jahr', key: 'thisYear', daterange: { from: roseDayjs().startOf('year'), to: roseDayjs() } },
  {
    label: 'Vorheriges Jahr',
    key: 'previousYear',
    daterange: { from: roseDayjs().subtract(1, 'year').startOf('year'), to: roseDayjs().startOf('year') },
  },
];

export function formatDayRangeMinimal(start: RoseDayJs, end: RoseDayJs) {
  if (start.year() !== end.year()) {
    return `${start.format('D.MM.YY')} - ${end.format('D.MM.YY')}`;
  } else if (start.month() !== end.month()) {
    // If the dates are in different months, display both months
    return `${start.format('D. MMM')} - ${end.format('D. MMM')}`;
  } else if (start.date() !== end.date()) {
    // If both dates are in the same month, display month once
    return `${start.format('D.')} - ${end.format('D. MMM')}`;
  } else {
    return start.format('D. MMM');
  }
}

export function getWidgetOverrideDaterange(widgetSettings?: IWidgetSettings) {
  if (!widgetSettings?.overrideDaterange) {
    return null;
  }

  let drType = widgetSettings.overrideDaterangeType || 'today';
  let dr = widgetOverrideDateranges.find(dre => dre.key === drType);
  if (dr) {
    return dr;
  }

  if (drType !== 'custom') {
    throw new Error('unknown overrideDaterangeType: ' + drType);
  }

  let rangeStartType: { [key: string]: any } = {
    Heute: 'day',
    Wochenstart: 'week',
    Monatsstart: 'month',
    Quartalsstart: 'quarter',
    Jahresstart: 'year',
  };

  let rangeOffsetType: { [key: string]: any } = {
    Tage: 'day',
    Wochen: 'week',
    Monate: 'month',
    Quartale: 'quarter',
    Jahre: 'year',
  };

  let from = roseDayjs()
    .startOf(rangeStartType[widgetSettings.overrideDaterangeStartStart || 'Tage'] || 'day')
    .subtract(
      isNaN(widgetSettings.overrideDaterangeStartOffset as any) ? 0 : widgetSettings.overrideDaterangeStartOffset!,
      rangeOffsetType[widgetSettings.overrideDaterangeStartOffsetType || 'Tage'] || 'day',
    );

  let to = roseDayjs()
    .startOf(rangeStartType[widgetSettings.overrideDaterangeEndStart || 'Tage'] || 'day')
    .subtract(
      isNaN(widgetSettings.overrideDaterangeEndOffset as any) ? 0 : widgetSettings.overrideDaterangeEndOffset!,
      rangeOffsetType[widgetSettings.overrideDaterangeEndOffsetType] || 'day',
    );

  return { label: formatDayRangeMinimal(from, to), key: 'custom', daterange: { from, to } };
}
