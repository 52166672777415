import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import { vElementSize } from '@vueuse/components';
import { defineComponent } from 'vue';
import * as ss from 'simple-statistics';
import { getCacheKeyForKpiDefinition, kpiStore, formatPercentChange } from '@/state/kpiStore';
import { defaultThresholdColors, getTrendInfo } from '../../../../types';
import { IBenchmarkConfigTrendType } from '../../../../types/ts';
import KpiHeader from '@/components/widgets/KpiHeader.vue';
import { dashboardsStore } from '@/state/dashboardsStore';
import { benchmarkUnitNiceName } from '../../../../base';
import { benchmarkGetFormattedValue } from '@rose/common-ui';
var defaultConfig = {
  kpiKey: 'heute::score::empfang'
};
var component = defineComponent({
  components: {
    KpiHeader: KpiHeader
  },
  directives: {
    elementSize: vElementSize
  },
  props: {
    widgetSettings: {
      type: Object,
      "default": function _default() {
        return defaultConfig;
      }
    },
    kpiDefinition: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      dataHeader: {
        kpiValue: '',
        kpiDaterange: '',
        trendChange: '',
        trendPositive: false,
        trendNegative: false
      }
    };
  },
  computed: {
    threshholdInfoColor: function threshholdInfoColor() {
      if (this.widgetSettings.useThresholdColors) {
        if (this.dataHeader.kpiValue < this.widgetSettings.threshold1) {
          return this.widgetSettings.thresholdColor1 || defaultThresholdColors.thresholdColor1;
        }

        if (this.dataHeader.kpiValue > this.widgetSettings.threshold2) {
          return this.widgetSettings.thresholdColor3 || defaultThresholdColors.thresholdColor3;
        }

        return this.widgetSettings.thresholdColor2 || defaultThresholdColors.thresholdColor2;
      }

      return false;
    },
    kpiKey: function kpiKey() {
      return this.kpiDefinition.kpiKey;
    },
    currentKpiPath: function currentKpiPath() {
      return kpiStore.getters.findPathToKpiTreeItem(this.kpiKey);
    },
    currentKpiPathString: function currentKpiPathString() {
      return this.currentKpiPath.slice(0, -1).map(function (e) {
        return e.name;
      }).join(' / ');
    },
    unit: function unit() {
      return benchmarkUnitNiceName(this.kpiDefinition.unit);
    },
    trendType: function trendType() {
      var _a, _b;

      return (_b = (_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.trendType) !== null && _b !== void 0 ? _b : IBenchmarkConfigTrendType.none;
    },
    headerData: function headerData() {
      return this.dataHeader;
    },
    widgetGlobalContext: function widgetGlobalContext() {
      return dashboardsStore.getters.widgetGlobalContext;
    },
    dateRange: function dateRange() {
      return this.widgetGlobalContext.daterange;
    },
    formatKpiValue: function formatKpiValue() {
      var _this = this;

      return this.kpiDefinition.formatValue || function (v) {
        return benchmarkGetFormattedValue(v, _this.kpiDefinition.unit);
      };
    },
    kpiCacheKey: function kpiCacheKey() {
      return getCacheKeyForKpiDefinition(this.kpiDefinition, this.finalKpiContext);
    },
    kpiData: function kpiData() {
      return kpiStore.getters.getKpiCacheEntry(this.kpiCacheKey);
    },
    loading: function loading() {
      return this.kpiData.loading;
    },
    errorText: function errorText() {
      return this.kpiData.error;
    },
    finalKpiContext: function finalKpiContext() {
      var dateRange = {
        from: this.dateRange.to.clone().subtract(1, 'w'),
        to: this.dateRange.to
      };
      return Object.assign(Object.assign({}, dashboardsStore.getters.kpiGlobalContextForConfig(this.widgetSettings)), {
        from: dateRange.from.format('YYYY-MM-DD'),
        to: dateRange.to.format('YYYY-MM-DD')
      });
    }
  },
  watch: {
    kpiCacheKey: {
      handler: function handler() {
        void kpiStore.dispatch.loadKpiData({
          kpi: this.kpiDefinition,
          kpiContext: this.finalKpiContext
        });
      },
      immediate: true
    },
    kpiData: {
      handler: function handler() {
        if (!this.kpiData.loading && !this.kpiData.error) {
          this.handleRawData(this.kpiData.data);
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {},
  methods: {
    handleRawData: function handleRawData(rawData) {
      var _this2 = this;

      var _a;

      var kpiValueEntry = rawData.find(function (d) {
        return d.tag === _this2.dateRange.to.format('YYYY-MM-DD');
      });
      var regressionLine = ss.linearRegression(rawData.map(function (v, i) {
        return [i, v.value];
      }));
      var trendChange = regressionLine.m * 100;
      var hasTrend = rawData.length > 1;
      this.dataHeader = Object.assign({
        kpiValue: (_a = kpiValueEntry === null || kpiValueEntry === void 0 ? void 0 : kpiValueEntry.value.toFixed(0)) !== null && _a !== void 0 ? _a : -1,
        kpiDaterange: this.dateRange.to.format('DD. MMM'),
        trendChange: hasTrend ? formatPercentChange(trendChange) : ''
      }, getTrendInfo(trendChange, this.trendType));
    }
  }
});
component.defaultConfig = defaultConfig;
export default component;