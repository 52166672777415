import { defineComponent } from 'vue';
var defaultConfig = {
  heading: 'Überschrift'
};
var component = defineComponent({
  props: {
    widgetSettings: {
      type: Object,
      "default": function _default() {
        return defaultConfig;
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  mounted: function mounted() {},
  methods: {}
});
component.defaultConfig = defaultConfig;
export default component;