import { DashboardWidgetType } from '@/../../types';
import DemoWidget from './DemoWidget.vue';
import TestWidget from './TestWidget.vue';
import KpiWidget from './KpiWidget.vue';
import HeaderWidget from './HeaderWidget.vue';

export const widgetTypeToComponent: Record<DashboardWidgetType, any> = {
  [DashboardWidgetType.Demo]: DemoWidget,
  [DashboardWidgetType.Test]: TestWidget,
  [DashboardWidgetType.Kpi]: KpiWidget,
  [DashboardWidgetType.Header]: HeaderWidget,
};

export const widgetTypeToDefaultConfig: Record<DashboardWidgetType, any> = {} as Record<DashboardWidgetType, any>;

for (const [widgetType, widget] of Object.entries(widgetTypeToComponent)) {
  widgetTypeToDefaultConfig[widgetType as DashboardWidgetType] = widget?.defaultConfig ?? {};
}

export const widgetTypeToTitle: Record<DashboardWidgetType, string> = {
  [DashboardWidgetType.Kpi]: 'KPI-Widget',
  [DashboardWidgetType.Header]: 'Überschrift-Widget',
  [DashboardWidgetType.Demo]: 'Chart-Demo',
  [DashboardWidgetType.Test]: 'KPI-Display',
};
