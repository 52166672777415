/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import dayjs, { Dayjs, ConfigType, QUnitType, OpUnitType } from 'dayjs';
import 'dayjs/locale/de';
import arraySupport from 'dayjs/plugin/arraySupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// configure the plugins that we want to have
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(arraySupport);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

dayjs.tz.setDefault('Europe/Berlin');

dayjs.locale('de');

export const setDayjsLocale = (_locale: string) => {
  dayjs.locale(_locale);
};

export const roseDayjs = dayjs;
export type RoseDayJs = Dayjs;

// FIX TYPINGS
export function testIsBetween(
  testme: RoseDayJs,
  start: RoseDayJs,
  end: RoseDayJs,
  unit: OpUnitType,
  inclusivity: '()' | '[]',
): boolean {
  return testme.isBetween(start, end, unit, inclusivity);
}

export function createUTC(): RoseDayJs {
  return dayjs.utc();
}

export type RoseDayJsQUnitType = QUnitType | OpUnitType;
export type RoseDayJsConfigType = ConfigType;

declare module 'dayjs' {
  interface Dayjs {
    // quarterOfYear
    quarter(): number;
    quarter(quarter: number): RoseDayJs;
    add(value: number, unit: RoseDayJsQUnitType): RoseDayJs;
    subtract(value: number, unit: RoseDayJsQUnitType): RoseDayJs;
    startOf(unit: RoseDayJsQUnitType): RoseDayJs;
    endOf(unit: RoseDayJsQUnitType): RoseDayJs;
    isSame(date?: RoseDayJsConfigType, unit?: RoseDayJsQUnitType): boolean;
    isBefore(date?: RoseDayJsConfigType, unit?: RoseDayJsQUnitType): boolean;
    isAfter(date?: RoseDayJsConfigType, unit?: RoseDayJsQUnitType): boolean;

    // relativeTime
    fromNow(withoutSuffix?: boolean): string;
    from(compared: RoseDayJsConfigType, withoutSuffix?: boolean): string;
    toNow(withoutSuffix?: boolean): string;
    to(compared: RoseDayJsConfigType, withoutSuffix?: boolean): string;

    // duration
    add(duration: duration.Duration): RoseDayJs;
    subtract(duration: duration.Duration): RoseDayJs;

    // utc
    utc(keepLocalTime?: boolean): RoseDayJs;
    local(): RoseDayJs;
    isUTC(): boolean;
    utcOffset(offset: number | string, keepLocalTime?: boolean): RoseDayJs;

    // timezone
    tz(timezone?: string, keepLocalTime?: boolean): RoseDayJs;
    offsetName(type?: 'short' | 'long'): string | undefined;
  }
}
