import { factoryViewSettings, getBenchmarkConfig, ViewID, viewMap } from '@/../../base';
import {
  extractBenchmarkConfigItemFields,
  flattenBenchmarkConfigs,
  hkpFilterGroups,
  hkpFilters,
} from '@/../../base/src';
import { metricsApi } from '@/services/data';
import { authStore } from '@/state/authStore';
import { settingsStore } from '@/state/settingsStore';
import {
  benchmarkGetFormattedValue,
  betterAlarmFieldNames,
  formatApiErrorForUser,
  getHelpTextFromFeldKey,
} from '@rose/common-ui';
import { createDirectStore } from 'direct-vuex';
import { cloneDeep, get, split, startCase } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import {
  BenchmarkAnalysisType,
  BenchmarkBenchmarkType,
  BenchmarkConfigUnitType,
  BenchmarkEntwicklungType,
  heuteAlarmeKeys,
  IBenchmarkConfigItem,
  IBenchmarkConfigTrendType,
  IKpiContext,
  IKpiDefinition,
  PrivatFilterTypeUI,
} from '../../../types';

Vue.use(Vuex);

interface IKpiDataCacheEntry {
  loading: boolean;
  data: any | null;
  error: string | null;
}

export interface IKpiDefitionTree {
  name: string;
  icon?: string;
  forbidden?: boolean;
  kpiDef?: IKpiDefinition;
  childs?: IKpiDefitionTree[];
}

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    kpiDataCache: {} as { [key: string]: IKpiDataCacheEntry },
  }),
  getters: {
    benchmarkKpiTree(state): IBenchmarkConfigItem {
      let benchmarkConfig = getBenchmarkConfig(settingsStore.state.settings, [], 'prod');
      return benchmarkConfig;
    },
    benchmarkKpiDefinitionTree(state) {
      let benchmarkTree: IKpiDefitionTree = cloneDeep(kpiStore.getters.benchmarkKpiTree);

      let convertToKpiQueue: IKpiDefitionTree[] = [benchmarkTree];
      while (convertToKpiQueue.length > 0) {
        let item = convertToKpiQueue.pop()! as IBenchmarkConfigItem;

        if (!item.isHeader) {
          (item as IKpiDefitionTree).kpiDef = benchmarConfigItemToKpiDefinition(item, false);
        }

        item.childs?.forEach(child => convertToKpiQueue.push(child));

        if (item.smartValue) {
          let childs = (item.childs ?? []) as IKpiDefitionTree[];

          childs.push({
            name: 'Anteilig',
            kpiDef: benchmarConfigItemToKpiDefinition(item, true),
          } as IKpiDefitionTree);

          item.childs = childs as IBenchmarkConfigItem[];
        }
      }

      return benchmarkTree;
    },
    kpiDefinitions(state): IKpiDefinition[] {
      let tree = kpiStore.getters.kpiDefinitionTree;
      let convertToKpiQueue: IKpiDefitionTree[] = [...tree];
      let kpis: IKpiDefinition[] = [];

      while (convertToKpiQueue.length > 0) {
        let item = convertToKpiQueue.pop()!;

        if (item.kpiDef) {
          kpis.push(item.kpiDef);
        }

        item.childs?.forEach(child => convertToKpiQueue.push(child));
      }

      return kpis;
    },
    kpiDefinitionsAuthFiltered(state): IKpiDefinition[] {
      let tree = kpiStore.getters.kpiDefinitionTree;
      let convertToKpiQueue: IKpiDefitionTree[] = [...tree];
      convertToKpiQueue = convertToKpiQueue.filter(item => !item.forbidden);
      let kpis: IKpiDefinition[] = [];

      while (convertToKpiQueue.length > 0) {
        let item = convertToKpiQueue.pop()!;

        if (item.kpiDef) {
          kpis.push(item.kpiDef);
        }

        item.childs?.forEach(child => convertToKpiQueue.push(child));
      }

      return kpis;
    },
    kpiDefinitionTree(state): IKpiDefitionTree[] {
      return [
        {
          name: 'Heute',
          icon: 'fa-square-check',
          childs: [
            {
              name: 'Score',
              icon: 'fa-chart-line',
              childs: getHeuteScoreKpiDefinitions().map(kpiDef => ({ name: kpiDef.name, kpiDef })),
            },
            {
              name: 'Alarm',
              icon: 'fa-bell',
              childs: getHeuteAlarmsKpiDefinitions().map(kpiDef => ({ name: kpiDef.name, kpiDef })),
            },
          ],
          forbidden: !authStore.getters.canSeeView(ViewID.HEUTE),
        },
        {
          name: 'HKP',
          icon: 'fa-file',
          childs: getHkpKpiDefinitions(),
          forbidden: !authStore.getters.canSeeView(ViewID.HKP_MANAGER),
        },
        {
          name: 'Termin-Qualität',
          icon: 'fa-calendar-check',
          childs: [terminQualitaetKpi].map(kpiDef => ({ name: kpiDef.name, kpiDef })),
          forbidden: !authStore.getters.canSeeView(ViewID.TERMIN_QUALITAET),
        },
        {
          name: 'Benchmark',
          icon: 'fa-chart-line',
          childs: kpiStore.getters.benchmarkKpiDefinitionTree.childs,
          forbidden: !authStore.getters.canSeeView(ViewID.BENCHMARK),
        },
        {
          name: 'Abrechnung',
          icon: 'fa-euro-sign',
          childs: abrechnungVorschlagKpis.map(kpiDef => ({ name: kpiDef.name, kpiDef })),
          forbidden: !authStore.getters.canSeeView(ViewID.ABRECHNUNG),
        },
      ];
    },
    getKpiDefinition(context): (key: string) => IKpiDefinition | undefined {
      return (kpiKey: string) => kpiStore.getters.kpiDefinitions.find(kpi => kpi.kpiKey === kpiKey);
    },

    getKpiCacheEntry(state) {
      return (cacheKey: string) => state.kpiDataCache[cacheKey] || { loading: false, error: 'loading not triggered' };
    },

    findPathToKpiTreeItem: state => (kpiKey: string) => {
      // find the path to the selected kpi
      let path: IKpiDefitionTree[] = [];
      let findPath = (tree: readonly IKpiDefitionTree[]) => {
        for (let item of tree) {
          if (item.kpiDef && item.kpiDef.kpiKey === kpiKey) {
            path.push(item);
            return true;
          }

          if (item.childs && findPath(item.childs)) {
            path.push(item);
            return true;
          }
        }
        return false;
      };

      findPath(kpiStore.getters.kpiDefinitionTree);

      return path.reverse();
    },
  },
  mutations: {},
  actions: {
    async loadKpiData(context, { kpi, kpiContext }: { kpi: IKpiDefinition; kpiContext: IKpiContext }) {
      let cacheKey = getCacheKeyForKpiDefinition(kpi, kpiContext);

      if (context.state.kpiDataCache[cacheKey]) {
        return;
      }

      let cacheEntry: IKpiDataCacheEntry = {
        loading: true,
        data: null,
        error: null,
      };
      Vue.set(context.state.kpiDataCache, cacheKey, cacheEntry);

      try {
        let data = await kpi.loadKpiData(kpiContext);
        Vue.set(context.state.kpiDataCache, cacheKey, {
          loading: false,
          data,
          error: null,
        });
      } catch (e) {
        Vue.set(context.state.kpiDataCache, cacheKey, {
          loading: false,
          data: null,
          error: formatApiErrorForUser(e),
        });
      }
    },
  },
});

export const kpiStore = store;

export function getCacheKeyForKpiDefinition(kpi: IKpiDefinition, context: IKpiContext) {
  let parts = [kpi.kpiKey];

  if (kpi.supportsHistory) {
    parts.push(context.from, context.to);
  }
  if (kpi.supportsBehandlerFilter) {
    parts.push(context.teamIds?.join(',') || '-');
  }

  return parts.join('##');
}

// ************* create Kpi Definitions *************

const terminQualitaetKpi: IKpiDefinition = {
  kpiKey: 'hkp::terminqualitaet',
  name: 'Termine ohne Terminart',
  getViewLinkToDetails: context => `/${viewMap[ViewID.TERMIN_QUALITAET].path}`,
  loadKpiData: async context => {
    let rawData = await metricsApi.termine.getTerminqualitaetKpis({
      von: context.from,
      bis: context.to,
      privat: PrivatFilterTypeUI.Alle,
      behandler: context.teamIds?.join(','),
    });
    console.log('terminqualitaet rawData', rawData);

    return [
      {
        tag: context.to,
        value: +rawData['termineOhneTerminart'],
      },
    ];
  },
  supportsHistory: false,
  supportsBehandlerFilter: true,
  trendType: IBenchmarkConfigTrendType.none,
  aggregationType: 'sum',
  unit: BenchmarkConfigUnitType.TERMINE,
  authorizationView: ViewID.TERMIN_QUALITAET,
};

const abrechnungVorschlagKpis: IKpiDefinition[] = [
  { key: 'score', name: 'Abrechnungs Vorschlag Score', unit: BenchmarkConfigUnitType.PERCENT },
  { key: 'umsatzpotentialBema', name: 'Abrechnungs Vorschlag Umsatzpotential', unit: BenchmarkConfigUnitType.EURO },
].map(def => ({
  kpiKey: `hkp::abrechnungVorschlag::${def.key}`,
  name: def.name,
  getViewLinkToDetails: context => `/${viewMap[ViewID.ABRECHNUNG].path}`,
  loadKpiData: async context => {
    let rawData = await metricsApi.abrechnungsvorschlaege.getAbrechnungsKPIs({
      from: context.from,
      to: context.to,
    });
    console.log('abrechnungVorschlag rawData', rawData);

    return [
      {
        tag: context.to,
        value: rawData[def.key],
      },
    ];
  },
  supportsHistory: false,
  supportsBehandlerFilter: false,
  trendType: IBenchmarkConfigTrendType.none,
  aggregationType: def.unit === BenchmarkConfigUnitType.PERCENT ? 'avg' : 'sum',
  unit: def.unit,
  authorizationView: ViewID.ABRECHNUNG,
}));

function getHeuteAlarmsKpiDefinitions(): IKpiDefinition[] {
  return heuteAlarmeKeys.map(
    alarmkey =>
      ({
        kpiKey: 'heute::alarm::' + alarmkey,
        name: betterAlarmFieldNames[alarmkey] || startCase(alarmkey),
        helpText: getHelpTextFromFeldKey(alarmkey),
        trendType: IBenchmarkConfigTrendType.smallerisbetter,
        unit: BenchmarkConfigUnitType.PATIENTEN,
        authorizationView: ViewID.HEUTE,
        supportsBehandlerFilter: false,
        supportsHistory: false,
        loadKpiData: async context => {
          let rawData = await metricsApi.heute.getAlarmCounts({
            alarmkey,
            from: context.from,
            to: context.to,
          });

          return rawData.map(entry => ({ tag: entry.tag, value: +entry.count }));
        },
      } as IKpiDefinition),
  );
}

function getHeuteScoreKpiDefinitions(): IKpiDefinition[] {
  return ['empfang', 'prophylaxe', 'zahnarzt', 'abrechnung'].map(bereich => ({
    kpiKey: 'heute::score::' + bereich,
    name: bereich.charAt(0).toUpperCase() + bereich.slice(1),
    trendType: IBenchmarkConfigTrendType.biggerisbetter,
    supportsHistory: false,
    supportsBehandlerFilter: false,
    unit: BenchmarkConfigUnitType.PERCENT,
    aggregationType: 'avg',
    authorizationView: ViewID.HEUTE,
    getViewLinkToDetails: context => `/${viewMap[ViewID.HEUTE].path}/${bereich}`,
    loadKpiData: async context => {
      let rawData = await metricsApi.heute.getScore({
        bereich,
        from: context.from,
        to: context.to,
      });

      return rawData.map(entry => ({ tag: entry.tag, value: +entry.score }));
    },
  }));
}

function getHkpKpiDefinitions(): IKpiDefitionTree[] {
  return [
    ...Object.values(hkpFilterGroups([])).flatMap(entry => entry.filters),
    ...Object.entries(hkpFilters()).map(([key, value]) => ({ key, name: value.name })),
    ...Object.entries(factoryViewSettings).map(([key, value]) => ({ name: key, key: value.key })),
  ].map(hkpDef => ({
    name: hkpDef.name,
    childs: (
      [
        { key: 'hkpCount', suffix: 'Anzahl', unit: BenchmarkConfigUnitType.HKP },
        { key: 'patientCount', suffix: 'Patienten', unit: BenchmarkConfigUnitType.PATIENTEN },
        { key: 'totalSum', suffix: 'Umsatz', unit: BenchmarkConfigUnitType.EURO },
      ] as const
    ).map(subEntry => ({
      name: subEntry.suffix,
      kpiDef: {
        kpiKey: 'hkp::' + hkpDef.key + ':' + subEntry.key,
        name: subEntry.suffix,
        supportsHistory: false,
        supportsBehandlerFilter: true,
        unit: subEntry.unit,
        aggregationType: 'sum',
        authorizationView: ViewID.HKP_MANAGER,
        getViewLinkToDetails: context => `/${viewMap[ViewID.HKP_MANAGER].path}`,
        trendType: IBenchmarkConfigTrendType.none,
        loadKpiData: async context => {
          let res = await metricsApi.hkp.getFilteredHkp({
            filterkey: hkpDef.key,
            behandler: context.teamIds?.join(','),
          });
          console.log('res', res);

          return [
            {
              tag: context.to,
              value: res[subEntry.key],
            },
          ];
        },
      },
    })),
  }));
}

function benchmarConfigItemToKpiDefinition(item: IBenchmarkConfigItem, useSmartValue = false): IKpiDefinition {
  return {
    kpiKey: 'bench::' + item.key + (useSmartValue ? '::smart' : ''),
    name: (item.path ? [...item.path, item.name].join(' / ') : item.name) + (useSmartValue ? ' (Anteilig)' : ''),
    unit: (useSmartValue ? item.smartUnit : item.unit) || BenchmarkConfigUnitType.NONE,
    supportsHistory: true,
    aggregationType: useSmartValue ? 'avg' : 'sum',
    supportsBehandlerFilter: true,
    authorizationView: ViewID.BENCHMARK,
    trendType: item.trendType ?? IBenchmarkConfigTrendType.none,
    helpText: item.help,
    getViewLinkToDetails: context => `/${viewMap[ViewID.BENCHMARK].path}?tab=${item.tab}`,
    loadKpiData: async context => {
      const fields = extractBenchmarkConfigItemFields(item, useSmartValue);

      const filterTeams = context.teamIds || ['praxis'];

      let rawData = (await metricsApi.benchmark.getBenchmarkRaw({
        historyRows: fields,
        historyGrouping: 'day',
        params: {
          from: context.from,
          to: context.to,
          freerange: true,
          parts: [item.tab],
          atyp: BenchmarkAnalysisType.ENTWICKLUNG,
          etyp: BenchmarkEntwicklungType.MJ,
          btyp: BenchmarkBenchmarkType.PRAXIS,
          ids: filterTeams,
        } as any,
      })) as any[];

      rawData = rawData.map((b: any) => {
        let value: number;
        if (item.virtual) {
          // add json fields
          for (const f of fields) {
            if (f.includes('.')) {
              let realValue = get(b, f);
              let parts = split(f, '.');
              const l = parts.length;
              let bl: any = b;
              for (let i = 0; i < l; i++) {
                const p = parts[i];
                if (i === l - 1) {
                  bl[p] = realValue;
                } else {
                  bl[p] = {};
                  bl = bl[p];
                }
              }
            }
          }
          try {
            // tslint:disable-next-line
            value = eval(item.value!);
          } catch (e) {
            value = -1;
            console.error(`exception while eval value`, e, item);
          }
        } else {
          value = b[item.key];
        }

        if (useSmartValue) {
          let s = value;
          // tslint:disable-next-line
          value = eval(item.smartValue!);

          if (isNaN(value) || !isFinite(value)) {
            value = 0;
          }
        }

        return { ...b, value };
      });

      return rawData.map(entry => ({ tag: entry.tag as string, value: +entry.value }));
    },
  };
}

export const formatPercentChange = (percentChange: number) => {
  if (isNaN(percentChange) || !isFinite(percentChange)) {
    return '-';
  }
  return (percentChange > 0 ? '+' : '') + Math.round(percentChange) + '%';
};
