import "core-js/modules/es.array.concat.js";
import { widgetTypeToComponent, widgetTypeToDefaultConfig, widgetTypeToTitle } from '@/components/widgets/widgetConfig';
import { kpiStore } from '@/state/kpiStore';
import { cloneDeep } from 'lodash';
import Vue, { defineComponent } from 'vue';
import { DashboardWidgetType, defaultThresholdColors, heuteAlarmeKeys } from '../../../../types';
import { teamsStore } from '@/state/teamsStore';
import KpiSelector from '@/components/widgets/KpiSelector.vue';
import { VueEditor } from 'vue2-editor';
import { benchmarkUnitShortName } from '../../../../base';
import { isProd } from '@rose/common-ui';
import { widgetOverrideDateranges, getWidgetOverrideDaterange } from '@/state/dashboardsStore';
export default defineComponent({
  components: {
    KpiSelector: KpiSelector,
    VueEditor: VueEditor
  },
  props: {
    widgetConfig: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      editingWidgetConfig: {},
      showDialog: true,
      widgetTypeToComponent: widgetTypeToComponent,
      DashboardWidgetType: DashboardWidgetType,
      widgetTypeToTitle: widgetTypeToTitle,
      heuteAlarmKeys: heuteAlarmeKeys,
      defaultThresholdColors: defaultThresholdColors,
      widgetOverrideDateranges: widgetOverrideDateranges,
      isProd: isProd
    };
  },
  computed: {
    teams: function teams() {
      return teamsStore.getters.teams;
    },
    kpiDefinition: function kpiDefinition() {
      var _a;

      if ((_a = this.editingWidgetConfig.widgetSettings) === null || _a === void 0 ? void 0 : _a.kpiKey) {
        return kpiStore.getters.getKpiDefinition(this.editingWidgetConfig.widgetSettings.kpiKey);
      }

      return undefined;
    },
    unit: function unit() {
      if (!this.kpiDefinition) {
        return '';
      }

      return benchmarkUnitShortName(this.kpiDefinition.unit);
    },
    widgetSettings: function widgetSettings() {
      return this.editingWidgetConfig.widgetSettings;
    },
    benchmarkKpiTree: function benchmarkKpiTree() {
      return kpiStore.getters.benchmarkKpiTree.childs;
    },
    supportsBehandlerFilter: function supportsBehandlerFilter() {
      var _a;

      return !!((_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.supportsBehandlerFilter);
    },
    supportsHistory: function supportsHistory() {
      var _a;

      return !!((_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.supportsHistory);
    },
    overrideDaterangeFormatted: function overrideDaterangeFormatted() {
      var dr = getWidgetOverrideDaterange(this.editingWidgetConfig.widgetSettings);
      return dr ? "".concat(dr.daterange.from.format('DD.MM.YYYY'), " - ").concat(dr.daterange.to.format('DD.MM.YYYY')) : null;
    }
  },
  mounted: function mounted() {
    this.editingWidgetConfig = cloneDeep(Object.assign(Object.assign({}, this.widgetConfig), {
      widgetSettings: this.widgetConfig.widgetSettings || widgetTypeToDefaultConfig[this.widgetConfig.widgetType]
    }));
  },
  methods: {
    close: function close() {
      this.$emit('cancel'); // this.showDialog = false;
    },
    save: function save() {
      this.$emit('saveEdit', this.editingWidgetConfig);
    },
    duplicate: function duplicate() {
      this.$emit('duplicate', this.editingWidgetConfig);
    },
    vueSet: function vueSet(obj, key, value) {
      Vue.set(obj, key, value);
    }
  }
});