import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.reverse.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/web.timers.js";
import { vElementSize } from '@vueuse/components';
import { defineComponent } from 'vue';
import KpiHeader from '@/components/widgets/KpiHeader.vue';
import { dashboardsStore, formatDayRangeMinimal } from '@/state/dashboardsStore';
import { getCacheKeyForKpiDefinition, kpiStore, formatPercentChange } from '@/state/kpiStore';
import { benchmarkUnitShortName, roseDayjs } from '@rose/base';
import { benchmarkGetFormattedValue, logVueReactiveObject } from '@rose/common-ui';
import { highchartsOptions } from '@rose/common-ui/src/helpers/highchartOptions';
import { setOptions as setHightchartsOptions } from 'highcharts';
import { defaultThresholdColors, getTrendInfo, IBenchmarkConfigTrendType } from '../../../../types';
import { getWidgetOverrideDaterange } from '@/state/dashboardsStore'; // Highcharts set global options

setHightchartsOptions(highchartsOptions);
var defaultConfig = {
  kpiKey: 'neupatient'
};
var component = defineComponent({
  components: {
    KpiHeader: KpiHeader
  },
  directives: {
    elementSize: vElementSize
  },
  props: {
    widgetSettings: {
      type: Object,
      "default": function _default() {
        return defaultConfig;
      }
    },
    kpiDefinition: {
      type: Object,
      required: true
    }
  },
  data: function data(componentInstance) {
    return {
      tooltipData: null,
      tooltipXOffset: 0,
      dataHeader: {
        kpiValue: '-',
        kpiValueCompare: '-',
        kpiDaterange: '-',
        kpiDaterangeCompare: '-',
        trendChange: '-',
        trendPositive: false,
        trendNegative: false
      },
      baseSeries: [],
      compareSeries: [],
      categories: [],
      plotLines: []
    };
  },
  computed: {
    editMode: function editMode() {
      return dashboardsStore.getters.editMode;
    },
    chartOptions: function chartOptions() {
      // @ts-ignore
      var componentInstance = this;
      var plotBands = [];
      var zones = [];

      if (this.widgetSettings.useThresholdColors) {
        plotBands = [{
          from: 0,
          to: this.widgetSettings.threshold1,
          color: (this.widgetSettings.thresholdColor1 || defaultThresholdColors.thresholdColor1) + '40'
        }, {
          from: this.widgetSettings.threshold2,
          to: Infinity,
          color: (this.widgetSettings.thresholdColor3 || defaultThresholdColors.thresholdColor3) + '40'
        }];
        zones = [{
          value: this.widgetSettings.threshold1,
          color: this.widgetSettings.thresholdColor1 || defaultThresholdColors.thresholdColor1
        }, {
          value: this.widgetSettings.threshold2,
          color: this.widgetSettings.thresholdColor2 || defaultThresholdColors.thresholdColor2
        }, {
          color: this.widgetSettings.thresholdColor3 || defaultThresholdColors.thresholdColor3
        }]; // zones = [];
      }

      return {
        chart: {
          type: 'line',
          events: {
            load: function load() {
              // @ts-ignore
              var chart = this; // Add a vertical line when the tooltip is shown

              chart.tooltip.refresh = function (points) {
                // // Call the original refresh function
                // Tooltip.prototype.refresh.apply(this, arguments);
                // console.log('points', points);
                var mainPoint = points[0];
                var comparePoint = points[1];
                var percentChange = (mainPoint.y - comparePoint.y) / comparePoint.y * 100;
                var tooltipData = Object.assign({
                  category: mainPoint.category,
                  kpiValue: componentInstance.formatKpiValue(mainPoint.y),
                  kpiDaterange: roseDayjs(mainPoint.tag).format('DD. MMM'),
                  kpiValueCompare: componentInstance.formatKpiValue(comparePoint.y),
                  kpiDaterangeCompare: roseDayjs(comparePoint.tag).format('DD. MMM'),
                  trendChange: formatPercentChange(percentChange)
                }, getTrendInfo(mainPoint.y - comparePoint.y, componentInstance.trendType)); // console.log('tooltip refresh points', points[0], tooltipData);
                // @ts-ignore

                componentInstance.tooltipData = tooltipData; // @ts-ignore

                componentInstance.tooltipXOffset = points[0].clientX; // // Get the x value of the first point
                // const xValue = points[0].point.x;
                // // Add a plot line at the hovered point
                // chart.xAxis[0].addPlotBand({
                //   color: 'rgba(0, 0, 0, 0.1)', // Light gray color for the line
                //   width: 2,
                //   value: xValue,
                //   id: 'hoverLine',
                // });
              };

              chart.tooltip.hide = function () {
                // @ts-ignore
                componentInstance.tooltipData = null; // // Remove the plot band
                // chart.xAxis[0].removePlotBand('hoverLine');
              };
            }
          }
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false // This hides the legend

        },
        tooltip: {
          shared: true // Enable shared tooltips

        },
        xAxis: {
          // categories: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
          categories: this.categories,
          // type: 'datetime',
          title: {
            text: null // Remove x-axis title

          },
          lineWidth: 0,
          visible: true,
          labels: {
            enabled: false
          },
          plotLines: this.plotLines
        },
        yAxis: {
          title: {
            text: ''
          },
          // this creates some padding at y=0
          min: -1,
          tickAmount: 10,
          gridLineWidth: 0,
          visible: true,
          labels: {
            enabled: false
          },
          plotBands: plotBands
        },
        series: [{
          name: 'Last Week',
          // data: null as any, // Replace with actual data
          data: this.baseSeries,
          zones: zones,
          marker: {
            enabled: false // Disable markers (dots) for this series

          },
          lineWidth: 4,
          zIndex: 2,
          animation: false,
          color: '#4c42b1',
          dataLabels: {
            enabled: true,
            backgroundColor: '#4c42b1',
            // Semi-transparent background
            borderRadius: 4,
            style: {
              color: 'white',
              // Text color set to white
              textOutline: 'none'
            },
            y: -10,
            formatter: function formatter() {
              // Show data label only for the first and last points
              // @ts-ignore
              var self = this; // self.align = 'left';

              if (self.point.index === 0 || self.point.index === self.series.data.length - 1) {
                // @ts-ignore
                return componentInstance.formatKpiValue(self.y) + ' ' + componentInstance.unit; // return self.y;
              }

              return null; // Hide for other points
            }
          }
        }, {
          name: 'Week Before Last',
          // data: null as any, // Replace with actual data
          data: this.compareSeries,
          // zones,
          animation: false,
          marker: {
            enabled: false // Disable markers (dots) for this series

          },
          lineWidth: 4,
          zIndex: 1,
          color: 'lightgray'
        }]
      };
    },
    kpiKey: function kpiKey() {
      return this.kpiDefinition.kpiKey;
    },
    currentKpiPath: function currentKpiPath() {
      return kpiStore.getters.findPathToKpiTreeItem(this.kpiKey);
    },
    currentKpiPathString: function currentKpiPathString() {
      return this.currentKpiPath.slice(0, -1).map(function (e) {
        return e.name;
      }).join(' / ');
    },
    headerData: function headerData() {
      var _a, _b;

      return (_b = (_a = this.tooltipData) !== null && _a !== void 0 ? _a : this.dataHeader) !== null && _b !== void 0 ? _b : {
        kpiValue: 0,
        kpiValueCompare: 0,
        kpiDaterange: '-',
        kpiDaterangeCompare: '-',
        trendChange: '',
        trendPositiv: true
      };
    },
    unit: function unit() {
      return benchmarkUnitShortName(this.kpiDefinition.unit);
    },
    trendType: function trendType() {
      var _a, _b;

      return (_b = (_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.trendType) !== null && _b !== void 0 ? _b : IBenchmarkConfigTrendType.none;
    },
    widgetGlobalContext: function widgetGlobalContext() {
      return dashboardsStore.getters.widgetGlobalContext;
    },
    dateRange: function dateRange() {
      var _a;

      var overrideDaterange = getWidgetOverrideDaterange(this.widgetSettings);
      return (_a = overrideDaterange === null || overrideDaterange === void 0 ? void 0 : overrideDaterange.daterange) !== null && _a !== void 0 ? _a : dashboardsStore.getters.daterange;
    },
    formatKpiValue: function formatKpiValue() {
      var _this = this;

      return this.kpiDefinition.formatValue || function (v) {
        return benchmarkGetFormattedValue(v, _this.kpiDefinition.unit);
      };
    },
    kpiCacheKey: function kpiCacheKey() {
      if (!this.kpiDefinition) {
        return '';
      }

      return getCacheKeyForKpiDefinition(this.kpiDefinition, this.finalKpiContext);
    },
    kpiData: function kpiData() {
      return kpiStore.getters.getKpiCacheEntry(this.kpiCacheKey);
    },
    loading: function loading() {
      return this.kpiData.loading;
    },
    errorText: function errorText() {
      return this.kpiData.error;
    },
    finalKpiContext: function finalKpiContext() {
      var compareRange = this.getCompareDaterange(this.dateRange);
      return Object.assign(Object.assign({}, dashboardsStore.getters.kpiGlobalContextForConfig(this.widgetSettings)), {
        from: compareRange.from.format('YYYY-MM-DD'),
        to: this.dateRange.to.format('YYYY-MM-DD')
      });
    }
  },
  watch: {
    kpiCacheKey: {
      handler: function handler() {
        void kpiStore.dispatch.loadKpiData({
          kpi: this.kpiDefinition,
          kpiContext: this.finalKpiContext
        });
      },
      immediate: true
    },
    kpiData: {
      handler: function handler() {
        if (!this.kpiData.loading && !this.kpiData.error) {
          this.handleRawData(this.kpiData.data);
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.sizeChanged();
  },
  methods: {
    getCompareDaterange: function getCompareDaterange(dateRange) {
      var compareRangeEnd = dateRange.from.subtract(1, 'days');
      var drDayCount = dateRange.to.diff(dateRange.from, 'days');
      var compareRangeStart = compareRangeEnd.subtract(drDayCount, 'days');
      return {
        from: compareRangeStart,
        to: compareRangeEnd
      };
    },
    createWeekdayChartDataFromRaw: function createWeekdayChartDataFromRaw(_ref) {
      var rawData = _ref.rawData,
          dateRange = _ref.dateRange,
          compareRange = _ref.compareRange;
      var mainRawSet = rawData.filter(function (entry) {
        return roseDayjs(entry.tag, 'YYYY-MM-DD').isBetween(dateRange.from, dateRange.to);
      });
      var compareRawSet = rawData.filter(function (entry) {
        return roseDayjs(entry.tag, 'YYYY-MM-DD').isBetween(compareRange.from, compareRange.to);
      });

      var shiftArray = function shiftArray(arr, shiftBy) {
        var length = arr.length;
        shiftBy = (shiftBy % length + length) % length;
        return arr.slice(shiftBy).concat(arr.slice(0, shiftBy));
      };

      var mostRecentDay = dateRange.to.day();
      var categories = shiftArray(['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'], mostRecentDay);
      var dayNumbers = [1, 2, 3, 4, 5, 6, 0];
      var mainSet = shiftArray(dayNumbers, mostRecentDay).map(function (dayNumber) {
        var d = mainRawSet.find(function (entry) {
          return roseDayjs(entry.tag, 'YYYY-MM-DD').day() === dayNumber;
        });
        return {
          y: d ? d.value : 0,
          // null,
          tag: d === null || d === void 0 ? void 0 : d.tag
        };
      });
      var compareSet = shiftArray(dayNumbers, mostRecentDay).map(function (dayNumber) {
        var d = compareRawSet.find(function (entry) {
          return roseDayjs(entry.tag, 'YYYY-MM-DD').day() === dayNumber;
        });
        return {
          y: d ? d.value : 0,
          // null,
          tag: d === null || d === void 0 ? void 0 : d.tag
        };
      });
      return {
        mainSet: mainSet,
        compareSet: compareSet,
        categories: categories
      };
    },
    createMonthChartDataFromRaw: function createMonthChartDataFromRaw(_ref2) {
      var rawData = _ref2.rawData,
          dateRange = _ref2.dateRange,
          compareRange = _ref2.compareRange;
      var mainRawSet = rawData.filter(function (entry) {
        return roseDayjs(entry.tag, 'YYYY-MM-DD').isBetween(dateRange.from, dateRange.to);
      });
      var compareRawSet = rawData.filter(function (entry) {
        return roseDayjs(entry.tag, 'YYYY-MM-DD').isBetween(compareRange.from, compareRange.to);
      });
      var daysInRange = dateRange.to.diff(dateRange.from, 'days');
      var categories = Array(daysInRange).fill(0).map(function (_, i) {
        return roseDayjs(dateRange.to).subtract(i, 'day').date();
      }).reverse();
      var mainSet = categories.map(function (dayNumber) {
        var d = mainRawSet.find(function (entry) {
          return roseDayjs(entry.tag, 'YYYY-MM-DD').date() === dayNumber;
        });
        return {
          y: d ? d.value : 0,
          // null,
          tag: d === null || d === void 0 ? void 0 : d.tag
        };
      });
      var compareSet = categories.map(function (dayNumber) {
        var d = compareRawSet.find(function (entry) {
          return roseDayjs(entry.tag, 'YYYY-MM-DD').date() === dayNumber;
        });
        return {
          y: d ? d.value : 0,
          // null,
          tag: d === null || d === void 0 ? void 0 : d.tag
        };
      });
      return {
        mainSet: mainSet,
        compareSet: compareSet,
        categories: categories.map(function (d) {
          return "".concat(d, ".");
        })
      };
    },
    createChartDataFromRaw: function createChartDataFromRaw(_ref3) {
      var rawData = _ref3.rawData,
          dateRange = _ref3.dateRange,
          compareRange = _ref3.compareRange;
      var daysInRange = dateRange.to.diff(dateRange.from, 'days') + 1; // +1 because we have inclusive date range

      var categories = Array(daysInRange).fill(0).map(function (_, i) {
        return i;
      });
      var mainSet = categories.map(function (dayOffset) {
        var d = rawData.find(function (entry) {
          return entry.tag === roseDayjs(dateRange.from).add(dayOffset, 'day').format('YYYY-MM-DD');
        });
        return {
          y: d ? d.value : 0,
          // null,
          tag: d === null || d === void 0 ? void 0 : d.tag
        };
      });
      var compareSet = categories.map(function (dayOffset) {
        var d = rawData.find(function (entry) {
          return entry.tag === roseDayjs(compareRange.from).add(dayOffset, 'day').format('YYYY-MM-DD');
        });
        return {
          y: d ? d.value : 0,
          // null,
          tag: d === null || d === void 0 ? void 0 : d.tag
        };
      });
      return {
        mainSet: mainSet,
        compareSet: compareSet,
        categories: categories.map(function (d) {
          return "+".concat(d);
        })
      };
    },
    handleRawData: function handleRawData(rawData) {
      var dateRange = this.dateRange;
      var compareRange = this.getCompareDaterange(dateRange);
      var chartData;
      chartData = this.createChartDataFromRaw({
        rawData: rawData,
        dateRange: dateRange,
        compareRange: compareRange
      });
      logVueReactiveObject('chartData', chartData);
      var aggregationFunctions = {
        sum: function sum(nums) {
          return nums.reduce(function (sum, n) {
            return sum + n;
          }, 0);
        },
        avg: function avg(nums) {
          return nums.reduce(function (sum, n) {
            return sum + n;
          }, 0) / nums.length;
        }
      };
      var aggregationFunctionsPrefix = {
        sum: '',
        avg: '⌀ '
      };
      var aggregationFunction = aggregationFunctions[this.kpiDefinition.aggregationType];
      var aggregationPrefix = aggregationFunctionsPrefix[this.kpiDefinition.aggregationType];
      var kpiValueAgg = aggregationFunction(chartData.mainSet.map(function (entry) {
        var _a;

        return (_a = entry.y) !== null && _a !== void 0 ? _a : 0;
      }));
      var kpiValueCompareAgg = aggregationFunction(chartData.compareSet.map(function (entry) {
        var _a;

        return (_a = entry.y) !== null && _a !== void 0 ? _a : 0;
      }));
      var percentChange = (kpiValueAgg - kpiValueCompareAgg) / kpiValueCompareAgg * 100;
      var formatVal = this.formatKpiValue;
      this.dataHeader = Object.assign({
        kpiValue: aggregationPrefix + formatVal(kpiValueAgg),
        kpiValueCompare: aggregationPrefix + formatVal(kpiValueCompareAgg),
        kpiDaterange: formatDayRangeMinimal(dateRange.from, dateRange.to),
        kpiDaterangeCompare: formatDayRangeMinimal(compareRange.from, compareRange.to),
        trendChange: formatPercentChange(percentChange)
      }, getTrendInfo(percentChange, this.kpiDefinition.trendType));
      this.setChartData(chartData);
    },
    setChartData: function setChartData(_ref4) {
      var categories = _ref4.categories,
          compareSet = _ref4.compareSet,
          mainSet = _ref4.mainSet;
      this.baseSeries = mainSet;
      this.compareSeries = compareSet;
      this.categories = categories;

      if (categories.length < 40) {
        this.plotLines = categories.map(function (_, i) {
          return {
            width: 2,
            value: i,
            color: '#ccc',
            dashStyle: 'Dot'
          };
        });
      } else {
        this.plotLines = [];
      }
    },
    sizeChanged: function sizeChanged() {
      var _this2 = this;

      setTimeout(function () {
        _this2.reflowChart();
      }, 0);
    },
    reflowChart: function reflowChart() {
      var _a, _b; // @ts-ignore


      (_b = (_a = this.$refs.highcharts) === null || _a === void 0 ? void 0 : _a.chart) === null || _b === void 0 ? void 0 : _b.reflow();
    }
  }
});
component.defaultConfig = defaultConfig;
export default component;